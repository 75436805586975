import React from 'react';
import cn from 'classnames';

import styles from './S8.module.scss';

export default function S8(props) {
  return (
    <section className={cn(styles.section8, 's8')}>
      <div className={styles.flexCol}>
        <div className={styles.flexCol1}>
          <h2 className={styles.wrapper20}>Our Client</h2>

          <div className={styles.flexRow}>
            <div className={styles.flexRow__item}>
              <hr size={1} className={styles.line} />
            </div>
            <div className={styles.flexRow__spacer} />
            <div className={styles.flexRow__item1}>
              <h1 className={styles.title}>合作廠商</h1>
            </div>
          </div>
        </div>

        <div className={styles.flexRow1}>
          <div className={styles.flexRow1__item}>
            <img
              src={require('assets/74ef2886b2df4d45b36a7607caaca6a3.png')}
              alt="alt text"
              className={styles.image9}
            />
          </div>
          <div className={styles.flexRow1__spacer} />
          <div className={styles.flexRow1__item1}>
            <div className={styles.grid}>
              <div className={styles.grid__item}>
                <div className={styles.flexRow2}>
                  <div className={styles.flexRow2__item}>
                    <img
                      src={require('assets/d154c7a1f1da0fc8c94348b172307e16.svg').default}
                      alt="alt text"
                      className={styles.image10}
                    />
                  </div>
                  <div className={styles.flexRow2__spacer} />
                  <div className={styles.flexRow2__item1}>
                    <h2 className={styles.medium_title5}>桃園市立圖書館</h2>
                  </div>
                </div>
              </div>
              <div className={styles.grid__item}>
                <div className={styles.flexRow3}>
                  <div className={styles.flexRow3__item}>
                    <img
                      src={require('assets/a7abf8d5443fe6d5a9a58fa0fe1f2c55.svg').default}
                      alt="alt text"
                      className={styles.image13}
                    />
                  </div>
                  <div className={styles.flexRow3__spacer} />
                  <div className={styles.flexRow3__item1}>
                    <h2 className={styles.medium_title51}>外貿協會</h2>
                  </div>
                </div>
              </div>
              <div className={styles.grid__item}>
                <div className={styles.flexRow4}>
                  <div className={styles.flexRow4__item}>
                    <img
                      src={require('assets/5be3033cfe2d2bd7a3c9841725263c91.svg').default}
                      alt="alt text"
                      className={styles.image11}
                    />
                  </div>
                  <div className={styles.flexRow4__spacer} />
                  <div className={styles.flexRow4__item1}>
                    <h2 className={styles.medium_title52}>台北市立圖書館</h2>
                  </div>
                </div>
              </div>
              <div className={styles.grid__item}>
                <div className={styles.flexRow3}>
                  <div className={styles.flexRow3__item2}>
                    <img
                      src={require('assets/0b72d0a92d097235abc3ff60488d4d19.png')}
                      alt="alt text"
                      className={styles.icon3}
                    />
                  </div>
                  <div className={styles.flexRow3__spacer1} />
                  <div className={styles.flexRow3__item3}>
                    <h2 className={styles.medium_title53}>凌網科技</h2>
                  </div>
                </div>
              </div>
              <div className={styles.grid__item}>
                <div className={styles.flexRow4}>
                  <div className={styles.flexRow4__item2}>
                    <img
                      src={require('assets/005a043394328d54dd4215630aef9782.png')}
                      alt="alt text"
                      className={styles.image12}
                    />
                  </div>
                  <div className={styles.flexRow4__spacer1} />
                  <div className={styles.flexRow4__item3}>
                    <h2 className={styles.medium_title54}>全國加油站</h2>
                  </div>
                </div>
              </div>
              <div className={styles.grid__item}>
                <px-grid
                  track-style="flex-grow: 1;"
                  x="0% 59.11% 40.89%"
                  y="0px minmax(0px, max-content) 0px"
                  lg-x="0% 56.6% 43.4%"
                  lg-y="0px minmax(0px, max-content) 0px"
                  md-x="0% 47.85% 52.15%"
                  md-y="0px minmax(0px, max-content) 0px"
                  sm-x="0% clamp(120px, 23.2%, 23.2%) 76.8%"
                  sm-y="0px minmax(0px, max-content) 0px"
                  xs-x="0% clamp(110px, 23.2%, 23.2%) 76.8%"
                  xs-y="0px minmax(0px, max-content) 0px"
                  xxs-x="0% clamp(100px, 23.2%, 23.2%) 76.8%"
                  xxs-y="0px minmax(0px, max-content) 0px"
                  tn-x="0% clamp(80px, 23.2%, 23.2%) 76.8%"
                  tn-y="0px minmax(0px, max-content) 0px">
                  <img
                    className={styles.image14}
                    src={require('assets/283358adaf047a1eef0e1b9f9808d5d1.png')}
                    alt="alt text"
                  />
                </px-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

S8.inStorybook = true;
