import React from 'react';
import cn from 'classnames';

import styles from './Footer_Form.module.scss';

export default function Footer_Form(props) {
  return (
    <div className={cn(styles.root, 'footer-form')}>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__item}>
          <img
            src={require('assets/fb8956870586964ea72b5322fdfa19d0.svg').default}
            alt="alt text"
            className={styles.image}
          />
        </div>
        <div className={styles.wrapper__spacer} />
        <div className={styles.wrapper__item1}>
          <div className={styles.wrapper1}>
            <div className={styles.wrapper2}>
              <div className={styles.wrapper2__item}>
                <img
                  src={require('assets/395eede2296d0aaada8616560813b737.svg').default}
                  alt="alt text"
                  className={styles.icon1}
                />
              </div>
              <div className={styles.wrapper2__spacer} />
              <div className={styles.wrapper2__item1}>
                <h2 className={styles.medium_title1}>台北市信義路四段 170 號 3F</h2>
              </div>
            </div>

            <div className={styles.wrapper2}>
              <div className={styles.wrapper2__item2}>
                <img
                  src={require('assets/bfbfe6aafd582d21647cbee88b2d7b73.svg').default}
                  alt="alt text"
                  className={styles.icon1}
                />
              </div>
              <div className={styles.wrapper2__spacer1} />
              <div className={styles.wrapper2__item3}>
                <h2 className={styles.medium_title11}>official@joocatech.com</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className={styles.medium_title}>Website Powered by Jooca Inc.</h2>
      <img src={require('assets/7afc49b5b31ff3e5e5e08aca54c6faa1.png')} alt="alt text" className={styles.decorator} />
    </div>
  );
}

Footer_Form.inStorybook = true;
