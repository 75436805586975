import React from 'react';
import cn from 'classnames';

import styles from './Header.module.scss';

export default function Header(props) {
  return (
    <section className={cn(styles.section1, 'header')}>
      <div className={styles.flexRow}>
        <div className={styles.flexRow__item}>
          <img
            src={require('assets/96953cc684a28ee7f6c02849d5db04e5.svg').default}
            alt="alt text"
            className={styles.image1}
          />
        </div>
        <div className={styles.flexRow__spacer} />
        <div className={styles.flexRow__item1}>
          <div className={styles.flexRow1}>
            <div className={styles.flexRow1__item}>
              <h3 onClick={() => window.open('/#OurService', '_self')} className={styles.subtitle}>
                服務項目
              </h3>
            </div>
            <div className={styles.flexRow1__spacer} />
            <div className={styles.flexRow1__item}>
              <h3 onClick={() => window.open('/#OurProjectCase', '_self')} className={styles.subtitle1}>
                作品案例
              </h3>
            </div>
            <div className={styles.flexRow1__spacer} />
            <div className={styles.flexRow1__item1}>
              <h3 onClick={() => window.open('/#ContactUS', '_self')} className={styles.subtitle2}>
                聯絡我們
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Header.inStorybook = true;
