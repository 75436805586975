import React from 'react';
import cn from 'classnames';
import S8 from 'components/S8';
import Footer_Form from 'components/Footer_Form';
import Headroom from 'react-headroom';
import Header from 'components/Header';

import section2Styles from './Jooca_web_section2.module.scss';
import section3Styles from './Jooca_web_section3.module.scss';
import section4Styles from './Jooca_web_section4.module.scss';
import section5Styles from './Jooca_web_section5.module.scss';
import section6Styles from './Jooca_web_section6.module.scss';
import section7Styles from './Jooca_web_section7.module.scss';
import styles from './Jooca_web.module.scss';

function renderSection2(props) {
  return (
    <section className={section2Styles.section2}>
      <div className={section2Styles.group}>
        <img
          src={require('assets/43aabdf5f29950d12e37644191c894fc.png')}
          alt="alt text"
          className={section2Styles.decorator}
        />

        <div className={section2Styles.flexCol}>
          <div className={section2Styles.flexCol1}>
            <h1 className={section2Styles.big_title}>您搭建產品服務時的最佳夥伴</h1>
            <h2 className={section2Styles.medium_title}>我們專注打造經營用戶最佳產品，幫助帶動企業成長</h2>
            <div className={section2Styles.flexCol1__item}>
              <button onClick={() => window.open('/missing', '_self')} className={section2Styles.btn}>
                <span className={section2Styles.btn__text}>立即諮詢</span>
              </button>
            </div>
          </div>

          <div className={section2Styles.group1}>
            <img
              src={require('assets/afb3682e1722cbdadefc76d0d97b0dbb.png')}
              alt="alt text"
              className={section2Styles.image}
            />
            <div className={cn(section2Styles.animate_text_box, 'animate_text')}>
              {/* apply animation */}
              <span className={cn(section2Styles.animate_text, 'animate_text')}>
                WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL
                AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE
                DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY WE ARE DIGITAL AGENCY
                WE ARE DIGITAL AGENCY
              </span>
            </div>
            <img
              src={require('assets/818ecd7dc6ee5aa30e38e7538deed3df.png')}
              alt="alt text"
              className={section2Styles.image2}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function renderSection3(props) {
  return (
    <section className={section3Styles.section3}>
      <img
        src={require('assets/00b6ef5594da69cf08ffd95f943b527a.png')}
        alt="alt text"
        className={section3Styles.cover}
      />
      <px-grid
        track-style="overflow: auto;
flex-grow: 1;"
        area-style="overflow: hidden;"
        x="0% 85.51% 14.49%"
        y="211px 710fr 120px"
        xxxl-x="1fr clamp(0%, 99.83%, 1040px) 1fr"
        xxxl-y="211px 710fr 120px"
        lg-x="0% 89.61% 10.39%"
        lg-y="216px 710fr 132px"
        md-x="200fr 78.26% 200fr"
        md-y="216px 710fr 126px"
        sm-x="280fr 72% 280fr"
        sm-y="178px 710fr 256px"
        xs-x="280fr 72% 280fr"
        xs-y="179px 710fr 207px"
        xxs-x="224fr 76.27% 224fr"
        xxs-y="158px 710fr 178px"
        tn-x="224fr 76.27% 224fr"
        tn-y="158px 710fr 180px">
        <div className={section3Styles.flexRow}>
          <div className={section3Styles.flexRow__item}>
            <div className={section3Styles.group}>
              <img
                src={require('assets/05157e0a344fd454cc4ae062f722f41e.png')}
                alt="alt text"
                className={section3Styles.image3}
                data-aos="fade-right"
                data-aos-delay={400}
                data-aos-duration={0}
              />
              <img
                src={require('assets/e0fc20aa9dbf2d59123ee57872a30cc8.png')}
                alt="alt text"
                className={section3Styles.image4}
                data-aos="fade-left"
                data-aos-delay={600}
                data-aos-duration={0}
              />
              <div
                style={{ '--src': `url(${require('assets/3b6be4e5b7c5ed6772cf5f85b1fba952.png')})` }}
                className={section3Styles.wrapper2}
                data-aos="fade-right"
                data-aos-delay={0}
                data-aos-easing="ease-in-out"
              />
            </div>
          </div>
          <div className={section3Styles.flexRow__spacer} />
          <div className={section3Styles.flexRow__item1}>
            <div className={section3Styles.wrapper3}>
              <div className={section3Styles.wrapper4}>
                <h2 className={section3Styles.medium_title2}>About us</h2>

                <div className={section3Styles.wrapper5}>
                  <div className={section3Styles.wrapper5__item}>
                    <hr size={1} className={section3Styles.line} />
                  </div>
                  <div className={section3Styles.wrapper5__spacer} />
                  <div className={section3Styles.wrapper5__item1}>
                    <h1 className={section3Styles.title}>關於我們</h1>
                  </div>
                </div>
              </div>

              <h3 className={section3Styles.subtitle1}>
                優佳隆科技股份有限公司(Jooca
                Inc.)是一家專注於移動互聯網的企業。核心團隊來源Facebook、雪豹(台北)、獵豹移動、HTC
                等相關行業頂尖企業，我們過去累積十幾年的產業know-how及創新，致力將經驗帶回給台灣的產業並提供全方位的App開發服務，透過高規格的UX設計與產品開發流程協助企業打造創新產品。
              </h3>
            </div>
          </div>
        </div>
      </px-grid>
    </section>
  );
}

function renderSection4(props) {
  return (
    <section className={section4Styles.section4}>
      <div className={section4Styles.flexCol}>
        <div className={section4Styles.wrapper3}>
          <div className={section4Styles.wrapper4}>
            <h2 className={section4Styles.medium_title2}>Ｗhy you choose Us</h2>

            <div className={section4Styles.wrapper5}>
              <div className={section4Styles.wrapper5__item}>
                <hr size={1} className={section4Styles.line} />
              </div>
              <div className={section4Styles.wrapper5__spacer} />
              <div className={section4Styles.wrapper5__item1}>
                <h1 className={section4Styles.title}>提供企業在經營用戶各種不同階段的解決方案</h1>
              </div>
            </div>
          </div>

          <h3 className={section4Styles.subtitle1}>
            現今開發產品面臨著各項挑戰，隨著少子化時代的來臨，企業的廣告成本與實際收入越來越不如預期，使得企業面臨前所未有的挑戰。如何透過產品來經營用戶將是企業提升收入的破局點，我們提供企業一種全新的解決方案。
          </h3>
        </div>

        <div className={section4Styles.flexCol__item}>
          <img
            src={require('assets/6398070e12026d84fb3f6da1458cbd31.png')}
            alt="alt text"
            className={section4Styles.image5}
            data-aos="fade-up"
            data-aos-delay={200}
          />
        </div>
      </div>
    </section>
  );
}

function renderSection5(props) {
  return (
    <section id={'OurService'} className={section5Styles.section5}>
      <div id={'OurService'} className={section5Styles.wrapper34}>
        <div className={section5Styles.rect} />
        <img
          src={require('assets/bd713afedbda226e7225b72e50a4dccd.png')}
          alt="alt text"
          className={section5Styles.decorator1}
        />

        <div className={section5Styles.flexCol}>
          <div className={section5Styles.flexCol__item}>
            <img
              src={require('assets/39692d7813c37a01d3846449f401bdb4.png')}
              alt="alt text"
              className={section5Styles.image16}
            />
          </div>

          <div className={section5Styles.wrapper35}>
            <h2 className={section5Styles.medium_title2}>Our service</h2>
            <h1 className={section5Styles.title}>服務項目</h1>
          </div>

          <div className={section5Styles.wrapper36}>
            <div className={section5Styles.wrapper36__item}>
              <div className={section5Styles.box2} data-aos="fade-up" data-aos-delay={100}>
                <div className={section5Styles.box2__item}>
                  <div className={section5Styles.wrapper37}>
                    <div className={section5Styles.wrapper37__item}>
                      <img
                        src={require('assets/0f764d9bfd1ad477cf8a7aae06eb9888.svg').default}
                        alt="alt text"
                        className={section5Styles.icon5}
                      />
                    </div>

                    <div className={section5Styles.wrapper38}>
                      <div className={section5Styles.wrapper39}>
                        <h2 className={section5Styles.wrapper391}>APP設計開發</h2>
                      </div>

                      <h3 className={section5Styles.subtitle4}>客製化創新產品、老產品更新增加產品佈局</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={section5Styles.wrapper36__spacer} />
            <div className={section5Styles.wrapper36__item}>
              <div className={section5Styles.box21} data-aos="fade-up" data-aos-delay={400}>
                <div className={section5Styles.box21__item}>
                  <div className={section5Styles.wrapper371}>
                    <div className={section5Styles.wrapper371__item}>
                      <img
                        src={require('assets/c3db412c698b462df74a3315adfb9ea8.svg').default}
                        alt="alt text"
                        className={section5Styles.icon5}
                      />
                    </div>

                    <div className={section5Styles.wrapper38}>
                      <div className={section5Styles.wrapper39}>
                        <h2 className={section5Styles.wrapper392}>產品市場調查與分析</h2>
                      </div>

                      <h3 className={section5Styles.subtitle41}>
                        系統性的用戶調查提出可行策略，提高產品的吸引力和銷售量
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={section5Styles.wrapper36__spacer1} />
            <div className={section5Styles.wrapper36__item1}>
              <div className={section5Styles.box22} data-aos="fade-up" data-aos-delay={700}>
                <div className={section5Styles.wrapper372}>
                  <div className={section5Styles.wrapper372__item}>
                    <img
                      src={require('assets/86f03d67b11f7dc6cd0c980d3635a031.svg').default}
                      alt="alt text"
                      className={section5Styles.icon5}
                    />
                  </div>

                  <div className={section5Styles.wrapper381}>
                    <div className={section5Styles.wrapper39}>
                      <h2 className={section5Styles.wrapper393}>產品推廣與優化</h2>
                    </div>

                    <h3 className={section5Styles.subtitle42}>協助達成優化廣告獲取流量，亦可增加廣告以外的獲客手段</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className={section5Styles.wrapper36__spacer} />
            <div className={section5Styles.wrapper36__item}>
              <div className={section5Styles.box23} data-aos="fade-up" data-aos-delay={1000}>
                <div className={section5Styles.wrapper372}>
                  <div className={section5Styles.wrapper372__item1}>
                    <img
                      src={require('assets/cdbffc3f062326cf8eb06dbaad12617a.svg').default}
                      alt="alt text"
                      className={section5Styles.icon5}
                    />
                  </div>

                  <div className={section5Styles.wrapper382}>
                    <div className={section5Styles.wrapper39}>
                      <h2 className={section5Styles.wrapper394}>產品成長相關諮詢</h2>
                    </div>

                    <h3 className={section5Styles.subtitle43}>提供當前市場更多理解，採取更具靈活性的成長策略</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function renderSection6(props) {
  return (
    <section id={'OurProjectCase'} className={section6Styles.section6}>
      <px-grid
        x="0px 1439fr 0px"
        y="160px 1689fr 0px"
        absolute
        lg-x="0px 1439fr 0px"
        lg-y="71px 1689fr 0px"
        xxs-x="0px 1439fr 0px"
        xxs-y="0px minmax(0px,800px) 0px">
        <div
          className={section6Styles.section61}
          style={{ '--src': `url(${require('assets/3abda26bd01429ec6899785bba7bb554.png')})` }}
        />
      </px-grid>

      <div className={section6Styles.flexCol}>
        <div className={section6Styles.wrapper4}>
          <div className={section6Styles.wrapper4__item}>
            <h2 className={section6Styles.medium_title2}>Our Project case</h2>
          </div>
          <div className={section6Styles.wrapper4__item1}>
            <div className={section6Styles.wrapper5}>
              <div className={section6Styles.wrapper5__item}>
                <hr size={1} className={section6Styles.line} />
              </div>
              <div className={section6Styles.wrapper5__spacer} />
              <div className={section6Styles.wrapper5__item1}>
                <h1 className={section6Styles.title}>案例介紹</h1>
              </div>
            </div>
          </div>
        </div>

        <div className={section6Styles.group}>
          <div className={section6Styles.group1}>
            <div
              style={{ '--src': `url(${require('assets/0c6afc8ec82b5d837333829816277efa.png')})` }}
              className={section6Styles.wrapper6}
            />

            <div className={section6Styles.flexRow}>
              <div className={section6Styles.flexRow__item}>
                <img
                  src={require('assets/19e31aed99b2bd5c82bb2596242a5b24.png')}
                  alt="alt text"
                  className={section6Styles.image6}
                  data-aos="fade-left"
                  data-aos-delay={300}
                  data-aos-duration={600}
                />
              </div>
              <div className={section6Styles.flexRow__spacer} />
              <div className={section6Styles.flexRow__item1}>
                <div className={section6Styles.wrapper7}>
                  <div className={section6Styles.wrapper8}>
                    <div className={section6Styles.wrapper8__item}>
                      <img
                        src={require('assets/147d7c56a285004fb2a30b0eb7344a46.svg').default}
                        alt="alt text"
                        className={section6Styles.icon1}
                      />
                    </div>

                    <div className={section6Styles.wrapper9}>
                      <h5 className={section6Styles.highlights}>Taipei Public Library</h5>
                      <h1 className={section6Styles.title1}>台北市圖書館</h1>
                    </div>
                  </div>

                  <div className={section6Styles.wrapper10}>
                    <h2 className={section6Styles.medium_title3}>最符合使用者需求的圖書館App</h2>
                    <h3 className={section6Styles.subtitle2}>
                      讓用戶可以在App內能完成所有圖書館中會遇到的場景，並且能在一台手機管理所有家裡成員的借閱證狀態，好用直覺的操作方式，讓它不同於其他的圖書館App。
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={section6Styles.flexRow1}>
          <div className={section6Styles.flexRow1__item}>
            <div className={section6Styles.wrapper11}>
              <div className={section6Styles.wrapper8}>
                <div className={section6Styles.wrapper8__item1}>
                  <img
                    src={require('assets/b4ae82d4fd9870b7d0ae75b8f6b377f0.png')}
                    alt="alt text"
                    className={section6Styles.icon11}
                  />
                </div>

                <div className={section6Styles.wrapper91}>
                  <div className={section6Styles.wrapper91__item}>
                    <h5 className={section6Styles.highlights1}>National Petroleum Co</h5>
                  </div>
                  <div className={section6Styles.wrapper91__item1}>
                    <h1 className={section6Styles.title11}>全國加油站</h1>
                  </div>
                </div>
              </div>

              <div className={section6Styles.wrapper12}>
                <h2 className={section6Styles.medium_title31}>異業合作提升產品價值實現增長</h2>
                <h3 className={section6Styles.subtitle3}>
                  以提升整體使用體驗、串接各種異業合作、並提高使用率為合作改版目標，持續創造出更多貼近使用者的功能。
                </h3>
              </div>
            </div>
          </div>
          <div className={section6Styles.flexRow1__spacer} />
          <div className={section6Styles.flexRow1__item1}>
            <img
              src={require('assets/af7537f8d42fad1b849dac48dd01c730.png')}
              alt="alt text"
              className={section6Styles.image61}
              data-aos="fade-left"
              data-aos-delay={300}
              data-aos-duration={600}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

function renderSection7(props) {
  return (
    <section className={section7Styles.section7}>
      <div
        style={{ '--src': `url(${require('assets/b4b83a0c6addc0072cc24233aa46103a.png')})` }}
        className={section7Styles.section71}
      />
      <px-grid
        track-style="flex-grow: 1;"
        x="320fr 61.9% 320fr"
        y="180px 1333fr 204px"
        xxxl-x="144fr clamp(0%, 80%, 960px) 144fr"
        xxxl-y="180px 1333fr 204px"
        lg-x="280fr 65% 280fr"
        lg-y="180px 1333fr 204px"
        md-x="200fr 72.22% 200fr"
        md-y="180px 1333fr 204px"
        sm-x="200fr 72.22% 200fr"
        sm-y="160px 1333fr 150px"
        xs-x="200fr 72.22% 200fr"
        xs-y="136px 1333fr 150px"
        xxs-x="200fr 72.22% 200fr"
        xxs-y="136px 1333fr 0px">
        <div className={section7Styles.flexCol}>
          <div className={section7Styles.flexRow}>
            <div className={section7Styles.flexRow__item}>
              <img
                src={require('assets/3f0ece75e2da70cce6cec93d1c9d1a4e.png')}
                alt="alt text"
                className={section7Styles.image6}
                data-aos="fade-right"
                data-aos-delay={300}
                data-aos-duration={600}
                data-aos-easing="ease-in-out"
              />
            </div>
            <div className={section7Styles.flexRow__spacer} />
            <div className={section7Styles.flexRow__item1}>
              <div className={section7Styles.wrapper14}>
                <div className={section7Styles.wrapper15}>
                  <div className={section7Styles.wrapper15__item}>
                    <img
                      src={require('assets/17bfc459c07dd70e4a2f59d7695c3404.png')}
                      alt="alt text"
                      className={section7Styles.icon2}
                    />
                  </div>

                  <div className={section7Styles.wrapper9}>
                    <h5 className={section7Styles.highlights}>The tool for cooking</h5>
                    <h1 className={section7Styles.title1}>小煮人</h1>
                  </div>
                </div>

                <div className={section7Styles.wrapper12}>
                  <h2 className={section7Styles.medium_title3}>不同於市面上的食譜App</h2>
                  <h3 className={section7Styles.subtitle3}>
                    「簡單直覺好用」「協助我省去好多時間」是最多用戶真實的心聲，我們以使用者真實的煮飯場景為出發點，研發出的煮飯App，協助用戶用食材找尋適合的食譜，規劃所需購買的食材清單，簡單就可以完成規劃步驟。
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className={section7Styles.flexRow1}>
            <div className={section7Styles.flexRow1__item}>
              <div className={section7Styles.wrapper16}>
                <div className={section7Styles.wrapper8}>
                  <div className={section7Styles.wrapper8__item}>
                    <img
                      src={require('assets/45d953c018c6d732f35e30f548f4f40c.svg').default}
                      alt="alt text"
                      className={section7Styles.icon1}
                    />
                  </div>

                  <div className={section7Styles.wrapper91}>
                    <h5 className={section7Styles.highlights1}>External Trade Development</h5>
                    <h1 className={section7Styles.title1}>外貿協會</h1>
                  </div>
                </div>

                <div className={section7Styles.wrapper17}>
                  <h2 className={section7Styles.medium_title4}>結合生物辨識兼具安全性的登入</h2>
                  <h3 className={section7Styles.subtitle31}>
                    我們與外貿協會共同開發一款應用程式，結合手機端生物辨識技術，提供快速且安全的登入方式，用戶再也不需要記住繁複的帳號密碼，也不用擔心帳號被盜用的風險，並結合單次密碼驗證碼功能，讓用戶可以更放心地登入帳號。
                  </h3>
                </div>
              </div>
            </div>
            <div className={section7Styles.flexRow1__spacer} />
            <div className={section7Styles.flexRow1__item1}>
              <img
                src={require('assets/2b56bdd312d8633ccd275c2f505cbc85.png')}
                alt="alt text"
                className={section7Styles.image61}
                data-aos="fade-left"
                data-aos-delay={300}
                data-aos-duration={600}
                data-aos-easing="ease-in-out"
              />
            </div>
          </div>
        </div>
      </px-grid>
    </section>
  );
}

export default function Jooca_web(props) {
  return (
    <React.Fragment>
      <Headroom tag="header" className="page-header">
        <nav className="max:show">
          <Header />
        </nav>
      </Headroom>

      <main className={cn(styles.main, 'jooca-web')}>
        <div className={styles.main__spacer} />
        <div className={styles.main__item}>{renderSection2(props)}</div>
        <div className={styles.main__item1}>{renderSection3(props)}</div>
        <div className={styles.main__item}>{renderSection4(props)}</div>
        <div className={styles.main__item}>{renderSection5(props)}</div>
        <div className={styles.main__item}>{renderSection6(props)}</div>
        <div className={styles.main__item}>{renderSection7(props)}</div>
        <div className={styles.main__item}>
          <px-grid
            track-style="flex-grow: 1;"
            x="0px 1440fr 0px"
            y="0px 800fr 0px"
            sm-x="0px 1440fr 0px"
            sm-y="0px minmax(680px,800fr) 0px"
            xxs-x="0px 1440fr 0px"
            xxs-y="0px minmax(550px,800fr) 0px">
            <div className={styles.section8}>
              <S8 />
            </div>
          </px-grid>
        </div>
        <div className={styles.main__item}>
          <div id={'ContactUS'} className={styles.component}>
            <Footer_Form />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

Jooca_web.inStorybook = true;
